import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAuthenticated } from './auth';


function AuthProvider({ children }) {
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!isAuthenticated(token) && location.pathname !== "/register") {
            navigate('/login');
        }
    }, [navigate]);

    return (
        <div>{children}</div>
    )
}

export default AuthProvider