import { Card, CardContent, Typography, Box, Grid } from "@mui/material";
import React from "react";

const UserFooter = () => {
  return (
    <Card
      sx={{
        padding: 1,
        backgroundColor: "common.lighterBlue",
        borderRadius: "8px",
      }}
    >
      <CardContent sx={{ paddingBottom: "0px !important" }}>
        <Grid container textAlign="center" spacing={2} mb={1}>
          <Grid item md={4} xs={4} sm={4}>
            <Box>
              <img
                alt="test image"
                src="/teddy_jason_img.png"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  borderRadius: "12px",
                }}
              />
              <Typography variant="body2">Nigen</Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={4} sm={4}>
            <Box>
              <img
                alt="test image"
                src="/teddy_jason_img.png"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  borderRadius: "12px",
                }}
              />
              <Typography variant="body2">Samanatha</Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={4} sm={4}>
            <Box>
              <img
                alt="test image"
                src="/teddy_jason_img.png"
                style={{
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                  borderRadius: "12px",
                }}
              />
              <Typography variant="body2">Billy</Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UserFooter;
