import { useEffect, useState, forwardRef } from "react";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Slide,
} from "@mui/material";
import { deleteCategory } from "../../_api/category";
import { Link } from "react-router-dom";

export const CategoryTable = (props) => {
  const {
    categories,
    categoryCount,
    onPageChange,
    onRowsPerPageChange,
    isLoading,
    page,
    rowsPerPage,
    ...other
  } = props;
  const [selectedCategories, setSelectedCategories] = useState([]);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!selectedCategories.length) {
      setSelectedCategories([]);
    }
  }, [categories]);

  const handleSelectAllCategories = (event) => {
    setSelectedCategories(
      event.target.checked ? categories.map((user) => user.id) : []
    );
  };

  const handleSelectOneCategory = (event, skillId) => {
    if (!selectedCategories.includes(skillId)) {
      setSelectedCategories((prevSelected) => [...prevSelected, skillId]);
    } else {
      setSelectedCategories((prevSelected) =>
        prevSelected.filter((id) => id !== skillId)
      );
    }
  };

  const handleDeleteCategory = async () => {
    toast.loading("Deleting...");
    try {
      await Promise.all(selectedCategories.map((id) => deleteCategory(id)));
      toast.dismiss();
      selectedCategories.length > 1
        ? toast.success("Categories deleted!")
        : toast.success("Category deleted!");
      queryClient.invalidateQueries("get-categories");
      setSelectedCategories([]);
    } catch (err) {
      setSelectedCategories([]);
      toast.dismiss();
      toast.error(err.message);
    }
  };

  const enableBulkActions = selectedCategories.length > 0;
  const selectedSomeCategories =
    selectedCategories.length > 0 &&
    selectedCategories.length < categories.length;
  const selectedAllCategories =
    selectedCategories.length === categories?.length;

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: "neutral.100",
          display: !enableBulkActions && "none",
          px: 2,
          py: 0.5,
        }}
      >
        <Checkbox
          checked={selectedAllCategories}
          indeterminate={selectedSomeCategories}
          onChange={handleSelectAllCategories}
        />
        <Button
          onClick={handleDeleteCategory}
          size="medium"
          style={{ marginRight: "10px" }}
          sx={{ ml: 2 }}
        >
          Delete
        </Button>

        {selectedCategories.length == 1 && ( // permission change
          <>
            <Link to={`/categories/edit/${selectedCategories[0]}`}>
              <Button size="small" endIcon={<EditIcon />}>
                Edit
              </Button>
            </Link>
          </>
        )}
      </Box>
      <Table sx={{ minWidth: 700 }}>
        <TableHead
          sx={{ visibility: enableBulkActions ? "collapse" : "visible" }}
        >
          <TableRow>
            <TableCell>Select</TableCell>
            <TableCell>#</TableCell>
            <TableCell>Name</TableCell>
            <TableCell sx={{ paddingLeft: "30px" }}>Icon</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            // permission change
            categories?.map((category, index) => {
              const isCategorySelected = selectedCategories.includes(
                category.id
              );

              return (
                <TableRow
                  hover
                  key={category?.id}
                  selected={isCategorySelected}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={isCategorySelected}
                      onChange={(event) =>
                        handleSelectOneCategory(event, category?.id)
                      }
                      value={isCategorySelected}
                    />
                  </TableCell>

                  <TableCell>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Box sx={{ ml: 1 }}>{page * rowsPerPage + index + 1}</Box>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Typography color="primary" variant="subtitle2">
                      {category.text}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <img width="60px" src={category.icon} alt="Category Icon" />
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={categoryCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </div>
  );
};
