// import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
// import PublicIcon from "@mui/icons-material/Public";
// import LocationCityIcon from "@mui/icons-material/LocationCity";
// import QuizIcon from "@mui/icons-material/Quiz";
// import PeopleIcon from "@mui/icons-material/People";
import CategoryIcon from "@mui/icons-material/Category";

import Events from "../pages/events";
import AddEvent from "../pages/events/add";
import Country from "../pages/country";
import AddCountry from "../pages/country/add";
import EditCountry from "../pages/country/edit";
import City from "../pages/city";
import AddCity from "../pages/city/add";
import EditCity from "../pages/city/edit";
import EditEvent from "../pages/events/edit";
import Users from "../pages/users";
import ViewEvent from "../pages/events/view";
import Categories from "../pages/categories";
import AddCategory from "../pages/categories/add";
import EditCategory from "../pages/categories/edit";
import UserReports from "../pages/user_reports";
import ViewUser from "../pages/users/view";

import PublicIcon from "@mui/icons-material/Public";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PeopleIcon from "@mui/icons-material/People";

import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
// import PublicIcon from "@mui/icons-material/Public";
// import LocationCityIcon from "@mui/icons-material/LocationCity";
// import QuizIcon from "@mui/icons-material/Quiz";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import ReportOutlinedIcon from "@mui/icons-material/ReportOutlined";
import BlockIcon from "@mui/icons-material/Block";
import BlockedUsers from "../pages/blocked-users";

import Dashboard from "../pages/dashboard";

import ChatIcon from "@mui/icons-material/Chat";

export const routes = [
  // {
  //   title: "Dashboard",
  //   path: "/dashboard",
  //   icon: DashboardOutlinedIcon,
  //   element: Dashboard,
  // },
  // {
  //   title: "Users",
  //   path: "/users",
  //   icon: PeopleRoundedIcon,
  //   element: Users,
  // },
  // {
  //   title: "Chat Requests",
  //   path: "/chat-requests",
  //   icon: ChatIcon,
  //   // element: ChatRequests,
  // },

  {
    title: "Events",
    path: "/events",
    icon: EventNoteOutlinedIcon,
    element: Events,
    children: [
      {
        title: "Add Event",
        path: "/add",
        element: AddEvent,
      },
      {
        title: "Edit Event",
        path: "/edit/:id",
        element: EditEvent,
      },
      {
        title: "View Event",
        path: "/view/:id",
        element: ViewEvent,
      },
    ],
  },
  {
    title: "Categories",
    path: "/categories",
    icon: CategoryIcon,
    element: Categories,
    children: [
      {
        title: "Add Category",
        path: "/add",
        element: AddCategory,
      },
      {
        title: "Edit Category",
        path: "/edit/:id",
        element: EditCategory,
      },
    ],
  },

  {
    title: "User Reports",
    path: "/user-reports",
    icon: ReportOutlinedIcon,
    element: UserReports,
  },
  {
    title: "Blocked Users",
    path: "/blocked-users",
    icon: BlockIcon,
    element: BlockedUsers,
  },
  {
    title: "Countries",
    path: "/countries",
    icon: PublicIcon,
    element: Country,
    children: [
      {
        title: "Add Country",
        path: "/add",
        element: AddCountry,
      },
      {
        title: "Edit Country",
        path: "/edit/:id",
        element: EditCountry,
      },
    ],
  },
  {
    title: "Cities",
    path: "/cities",
    icon: LocationCityIcon,
    element: City,
    children: [
      {
        title: "Add City",
        path: "/add",
        element: AddCity,
      },
      {
        title: "Edit City",
        path: "/edit/:id",
        element: EditCity,
      },
    ],
  },
  {
    title: "Users",
    path: "/users",
    icon: PeopleIcon,
    element: Users,
    children: [
      {
        title: "View user",
        path: "/view/:id",
        element: ViewUser,
      },
    ],
  },

  // {
  //   title: "Countries",
  //   path: "/countries",
  //   icon: PublicIcon,
  //   element: Country,
  //   children: [
  //     {
  //       title: "Add Country",
  //       path: "/add",
  //       element: AddCountry,
  //     },
  //     {
  //       title: "Edit Country",
  //       path: "/edit/:id",
  //       element: EditCountry,
  //     },
  //   ],
  // },
  // {
  //   title: "Cities",
  //   path: "/cities",
  //   icon: LocationCityIcon,
  //   element: City,
  //   children: [
  //     {
  //       title: "Add City",
  //       path: "/add",
  //       element: AddCity,
  //     },
  //     {
  //       title: "Edit City",
  //       path: "/edit/:id",
  //       element: EditCity,
  //     },
  //   ],
  // },
  // {
  //   title: "Questions",
  //   path: "/questions",
  //   icon: QuizIcon,
  //   element: Questions,
  //   children: [
  //     {
  //       title: "Add Question",
  //       path: "/add",
  //       element: AddQuestion
  //     },
  //     {
  //       title: "Edit Question",
  //       path: "/edit/:id",
  //       element: EditQuestion
  //     }
  //   ]
  // },
];
