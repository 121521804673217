import { Box, Card, CardContent } from "@mui/material";

const BankCard = () => {
  return (
    <Card sx={{ padding: 0 }}>
      <CardContent sx={{ padding: 0, paddingBottom: "0px !important" }}>
        <Box
          sx={{
            // width: "280px",
            // minWidth: "220px",
            height: "auto",
            p: 0,
            paddingBottom: "0px !important",
            overflow: "hidden",
            // borderRadius: "8px",
            // boxShadow: 3,
          }}
        >
          <img
            src="/bank_card.webp"
            alt="bank card"
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default BankCard;
