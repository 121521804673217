import { useEffect, useState, forwardRef } from "react";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Slide,
} from "@mui/material";
// import { deleteCountry } from "../../_api/country";
import { blockUser, unblockUser } from "../../_api/userReports";
import { Link } from "react-router-dom";

export const ReportedUsersTable = (props) => {
  const {
    reportedUsers,
    reportedUserCount,
    onPageChange,
    onRowsPerPageChange,
    isLoading,
    page,
    rowsPerPage,
    handleChangeDisplayedReports,
    reports,
    blockTable,
    ...other
  } = props;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleBlockUser = async (userId) => {
    try {
      toast.loading("Blocking User");
      await blockUser(userId);

      const newReports = reports.map((report) => {
        if (report.reported_user.id === userId) {
          return {
            ...report,
            reported_user: {
              ...report.reported_user,
              is_deleted: true,
            },
          };
        }
        return report;
      });
      handleChangeDisplayedReports(newReports);
      toast.dismiss();
      toast.success("User Blocked");
    } catch (err) {
      console.log(err);
      toast.dismiss();
      toast.error(err.message);
    }
  };

  const handleUnblockUser = async (userId) => {
    try {
      toast.loading("Unblocking User");
      await unblockUser(userId);
      const newReports = reports.map((report) => {
        if (report.reported_user.id === userId) {
          return {
            ...report,
            reported_user: {
              ...report.reported_user,
              is_deleted: false,
            },
          };
        }
        return report;
      });
      handleChangeDisplayedReports(newReports);
      toast.dismiss();
      toast.success("User Unblocked");
    } catch (err) {
      console.log(err);
      toast.dismiss();
      toast.error(err.message);
    }
  };

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: "neutral.100",
          display: "none",
          px: 2,
          py: 0.5,
        }}
      ></Box>
      <Table sx={{ minWidth: 700 }}>
        <TableHead sx={{ visibility: "visible" }}>
          <TableRow>
            {/* <TableCell>Select</TableCell> */}
            <TableCell>#</TableCell>
            {/* <TableCell>Image</TableCell> */}
            <TableCell>
              {blockTable ? "Blocked User" : "Reported User"}
            </TableCell>
            <TableCell>{blockTable ? "Blocked By" : "Reported By"}</TableCell>
            <TableCell>Reason</TableCell>
            {/* <TableCell>Height</TableCell> */}
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            // permission change
            reports?.map((report, index) => {
              const reportedUser = report.reported_user;
              const reportedBy = report.user;

              return (
                <TableRow
                  hover
                  key={reportedUser?.id}
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate(`/users/view/${reportedUser?.id}`)}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Box sx={{ ml: 1 }}>{page * rowsPerPage + index + 1}</Box>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Typography color="primary" variant="subtitle2">
                      {reportedUser.firstName}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography color="primary" variant="subtitle2">
                      {reportedBy.firstName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="primary" variant="subtitle2">
                      {report.reason}
                    </Typography>
                  </TableCell>
                  {!blockTable && (
                    <TableCell>
                      {reportedUser.is_deleted ? (
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "primary.main" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleUnblockUser(reportedUser.id);
                          }}
                        >
                          Unblock {reportedUser.firstName}
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "common.red" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBlockUser(reportedUser.id);
                          }}
                        >
                          Block {reportedUser.firstName}
                        </Button>
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={reportedUserCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </div>
  );
};
