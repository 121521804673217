import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormHelperText,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { uploadS3Media } from "../../_api/S3Services";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { createCountry, getCountry, updateCountry } from "../../_api/country";
import { useQuery, useQueryClient } from "react-query";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 characters required")
    .required("Required"),
  name: Yup.string()
    .min(2, "Minimum 2 characters required")
    .required("Required"),
});

const CountryForm = ({ isEdit }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [isUploading, setIsUploading] = useState(false);
  const { isLoading, data: country } = useQuery(
    ["get-country", id],
    () => getCountry(id),
    { enabled: isEdit }
  );
  const navigatte = useNavigate();
  const initialValues = {
    name: "",
    image: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      toast.loading("Please wait...");
      try {
        isEdit
          ? await updateCountry(id, { name: values.name, image: values.image })
          : await createCountry({ name: values.name, image: values.image });
        toast.dismiss();
        toast.success("Country Added");
        queryClient.invalidateQueries("get-countries");
        navigatte(-1);
      } catch (err) {
        toast.dismiss();
        toast.error(err?.message);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!isLoading && country) {
      formik.setFieldValue("name", country.name);
      formik.setFieldValue("image", country.image);
    }
  }, [isLoading, country]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h4" gutterBottom>
        Event Form
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          {formik.values.image && (
            <img
              width={"50%"}
              style={{ maxHeight: "300px", maxWidth: "300px" }}
              src={formik.values.image}
              alt="Uploaded File"
            />
          )}
          <input
            id="image"
            name="image"
            type="file"
            accept=".jpg, .jpeg, .png, .gif, .bmp, .svg"
            onChange={async (event) => {
              setIsUploading(true);
              const res = await uploadS3Media(event.target.files[0]);
              formik.setFieldValue(
                "image",
                process.env.REACT_APP_S3_BUCKET_LINK + res
              );
              setIsUploading(false);
            }}
            onBlur={formik.handleBlur}
            hidden
          />
          <label htmlFor="image">
            <Button
              startIcon={<FileUploadIcon />}
              color="primary"
              variant="outlined"
              component="span"
              fullWidth
              sx={{ py: 5 }}
            >
              {formik.values.image ? "Update Image" : "Upload Image"}
            </Button>
          </label>
          {formik.errors.image && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{formik.errors.image}</FormHelperText>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={formik.isSubmitting || isUploading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CountryForm;
