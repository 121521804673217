import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import { RateCardTable } from "./rate-card-table";
import parse from "html-react-parser";

const changeTimeToLocal = (time) => {
  const tempTime = moment.utc(time);
  return tempTime.local();
};

const EventDetailScreen = ({ eventData }) => {
  const { event, registrations, matches, rate_card_submissions } = eventData;
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 2 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        variant="text"
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <Card variant="outlined">
        <CardHeader
          sx={{ color: (theme) => theme.palette.primary.main }}
          title="Event Details"
        />
        <CardContent>
          <Typography variant="h6">{event?.venue}</Typography>
          <Typography variant="body1" sx={{ marginTop: 1 }}>
            {event?.address}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {changeTimeToLocal(event?.startTime).format("MMM D[,] hh:mm A")} to{" "}
            {changeTimeToLocal(event?.endTime).format("MMM D[,] hh:mm A")} - (
            {changeTimeToLocal(event?.date).format("YYYY")})
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              marginTop: 1,
            }}
          >
            <Typography variant="body1">Category:</Typography>
            <Typography variant="body1">{event?.category?.text}</Typography>
          </Box>
          <Typography variant="body1" sx={{ marginTop: 1 }}>
            {event && parse(event?.programDescription)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Location: ({event?.latitude}, {event?.longitude}) - Radius:{" "}
            {event?.radius}
          </Typography>

          <Grid container spacing={2} marginY={2}>
            {event?.event_images.map((image) => {
              return (
                <Grid item xs={12} sm={3}>
                  <Box
                    sx={{
                      position: "relative",
                      borderRadius: "8px",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <img
                      src={image}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>

      <Divider sx={{ margin: "20px 0" }} />

      <Card variant="outlined">
        <CardHeader
          sx={{ color: (theme) => theme.palette.primary.main }}
          title="Registrations"
        />
        <CardContent sx={{ maxHeight: "400px", overflow: "auto" }}>
          <List>
            {registrations?.map((registration, index) => (
              <>
                <ListItem key={registration.id}>
                  <ListItemText
                    primary={`${index + 1}.  ${registration.user.firstName}`}
                  />
                </ListItem>
                <Divider />
              </>
            ))}
            {registrations?.length === 0 && (
              <Typography variant="body2" color="text.secondary">
                No registrations available.
              </Typography>
            )}
          </List>
        </CardContent>
      </Card>

      <Divider sx={{ margin: "20px 0" }} />

      <Card variant="outlined">
        <CardHeader
          sx={{ color: (theme) => theme.palette.primary.main, fontWeight: 700 }}
          title="Matches"
        />
        <CardContent sx={{ maxHeight: "400px", overflow: "auto" }}>
          <List>
            {matches?.map((match) => (
              <>
                <ListItem key={match.id}>
                  <ListItemText
                    primaryTypographyProps={{
                      color: "primary.main",
                    }}
                    primary={`${match.male_user.firstName} ↔ ${match.female_user.firstName}`}
                    secondary={`Match Score: ${match.matchScore}`}
                  />
                </ListItem>
                <ListItem key={match.id}>
                  <ListItemText
                    primaryTypographyProps={{
                      color: "primary.main",
                    }}
                    primary={
                      match.dateEndTime &&
                      (match.femaleMeetAgain == "Yes" &&
                      match.maleMeetAgain == "Yes"
                        ? "Both wanna meet again"
                        : match.maleMeetAgain == "Yes"
                        ? `${match.male_user.firstName} wanna meet but ${match.female_user.firstName} denied to meet again!`
                        : match.femaleMeetAgain == "Yes"
                        ? `${match.female_user.firstName} wanna meet but ${match.male_user.firstName} denied to meet again!`
                        : "Both denied to meet again")
                    }
                    secondary={`Date time: ${
                      match.dateStartTime
                        ? `${changeTimeToLocal(match.dateStartTime).format(
                            "MMM D[,] hh:mm A"
                          )} - ${changeTimeToLocal(match.dateEndTime).format(
                            "MMM D[,] hh:mm A"
                          )}`
                        : "Date not started yet"
                    }`}
                  />
                </ListItem>
                <ListItem key={match.id}>
                  <ListItemText
                    primaryTypographyProps={{
                      color: "primary.main",
                    }}
                    primary={`${match.female_user.firstName} liked ${match.male_user.firstName}`}
                    secondary={match.femaleLiked ? "Yes" : "No"}
                  />
                  <ListItemText
                    primaryTypographyProps={{
                      color: "primary.main",
                    }}
                    primary={`${match.male_user.firstName} liked ${match.female_user.firstName}`}
                    secondary={match.maleLiked ? "Yes" : "No"}
                  />
                </ListItem>
                <Divider />
              </>
            ))}
            {matches?.length === 0 && (
              <Typography variant="body2" color="text.secondary">
                No match available.
              </Typography>
            )}
          </List>
        </CardContent>
      </Card>

      <Divider sx={{ margin: "20px 0" }} />

      <Card variant="outlined">
        <CardHeader
          sx={{ color: (theme) => theme.palette.primary.main }}
          title="Rate Card Submissions"
        />
        <CardContent sx={{ maxHeight: "400px", overflow: "auto" }}>
          <RateCardTable submissions={rate_card_submissions} />

          {/* {rate_card_submissions.map((submission, index) => (
                            <ListItem key={index}>
                                <ListItemText
                                    primary={`${submission.firstName}`}
                                />
                            </ListItem>
                        ))} */}
          {rate_card_submissions?.length === 0 && (
            <Typography variant="body2" color="text.secondary">
              No submissions available.
            </Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default EventDetailScreen;
