
import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { themeMain } from './theme/index';


const ThemeProviderCustom = ({ children }) => {
    return (
        <ThemeProvider theme={themeMain} >
            {children}
        </ThemeProvider>
    )
}

export default ThemeProviderCustom;