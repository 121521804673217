

import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Box, Button, FormHelperText, TextField, IconButton, InputAdornment, FormControl, InputLabel, OutlinedInput, Card, Container, Typography } from "@mui/material";
import toast from 'react-hot-toast'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Logo from "../layout/common/logo";
import { register } from "../_api";
import { isAuthenticated } from "./auth";
import { Link, useNavigate } from "react-router-dom";


const Register = () => {
    let token = localStorage.getItem("token")
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            submit: null,
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
            password: Yup.string().max(255).required("Password is required"),
        }),
        onSubmit: async (values, helpers) => {
            toast.loading("Please wait...")
            try {
                const res = await register(
                    {
                        email: values.email,
                        password: values.password,
                    });
                toast.dismiss()
                toast.success("Registered Successfully, Please login to your account")
                navigate("/login")
            } catch (err) {
                toast.dismiss();
                helpers.setErrors({ submit: err.status == 400 ? "An account with this email is already created" : "Something went wrong please try again!" });
            }
        },
    });

    useEffect(() => {
        if (isAuthenticated(token)) {
            navigate("/dashboard")
        }
    }, [token])
    return (
        <>
            <Box
                component="main"
                sx={{
                    backgroundColor: theme => theme.palette.common.bodybackground,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{
                        py: {
                            xs: "60px",
                            md: "120px",
                        },
                    }}
                >
                    <Card elevation={16} sx={{ p: 4 }}>
                        <Box
                            sx={{
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}
                        >
                            <Logo
                                sx={{
                                    height: 40,
                                    width: 40,
                                }}
                            />
                            <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
                                Sign up for the admin platform
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                flexGrow: 1,
                                mt: 3,
                            }}
                        >
                            <form noValidate onSubmit={formik.handleSubmit}>
                                <TextField
                                    autoFocus
                                    error={Boolean(formik.touched.email && formik.errors.email)}
                                    fullWidth
                                    helperText={formik.touched.email ? formik.errors.email : ""}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    type="email"
                                    value={formik.values.email}
                                />
                                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                                    <InputLabel htmlFor="outlined-adornment-password" shrink={true}>Password</InputLabel>
                                    <OutlinedInput
                                        error={Boolean(formik.touched.password && formik.errors.password)}
                                        label="Password"
                                        margin="none"
                                        name="password"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        value={formik.values.password}
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword((show) => !show)}
                                                    onMouseDown={(event) => {
                                                        event.preventDefault();
                                                    }}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                                {
                                    formik.errors.submit && (
                                        <Box sx={{ mt: 3 }}>
                                            <FormHelperText error>{formik.errors.submit}</FormHelperText>
                                        </Box>
                                    )
                                }
                                <Box sx={{ mt: 2 }}>
                                    <Button
                                        disabled={formik.isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Sign Up
                                    </Button>
                                    <Button
                                        component={Link}
                                        to="/login"
                                        fullWidth
                                        size="large"
                                        variant="text"
                                        sx={{ mt: 2 }}
                                    >
                                        Already have account ?
                                    </Button>
                                </Box>
                            </form >
                        </Box>


                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default Register;
