import { useState, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { getReportedUsers } from "../../_api/userReports";
import { ReportedUsersTable } from "../../components/user_reports/table";

const applyFilters = (data, filters) => {
  return data?.filter((item) => {
    if (filters.query) {
      let queryMatched = false;
      const properties = ["name"];

      properties.forEach((property) => {
        if (
          String(item.venue).toLowerCase().includes(filters.query.toLowerCase())
        ) {
          queryMatched = true;
        }
      });
      if (!queryMatched) {
        return false;
      }
    }
    return true;
  });
};

function BlockedUsers() {
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [reportedUsers, setReportedUsers] = useState([]);
  const [reports, setReports] = useState([]);

  const { isFetching, refetch } = useQuery(
    "get-reported-users",
    async () => await getReportedUsers(),
    {
      onSuccess: (data) => {
        const reports = data.filter((report) => report.is_blocked);
        setReports(reports);
        const reportedUsers = reports.map((report) => {
          return report.reported_user;
        });
        setReportedUsers(reportedUsers);
      },
      onError: (error) => {
        console.error("Failed to fetch reported users:", error);
      },
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const { isBlocked, setIsBlocked } = useState(false);

  const queryRef = useRef(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [filters, setFilters] = useState({
    query: "",
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null,
  });

  const applyPagination = (reportedUsers, page, rowsPerPage) =>
    reportedUsers?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleQueryChange = (event) => {
    event.preventDefault();
    setFilters((prevState) => ({
      ...prevState,
      query: queryRef.current?.value,
    }));
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangeDisplayedReports = (newReports) => {
    setReports(newReports);
  };

  const filteredreportedUsers = applyFilters(reportedUsers, filters);
  const paginatedreportedUsers = applyPagination(
    filteredreportedUsers,
    page,
    rowsPerPage
  );
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Box sx={{ mb: 4 }}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography variant="h4" color="primary">
                Blocked Users
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Card>
          {reportedUsers?.length ? (
            <>
              {/* <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexWrap: "wrap",
                  m: -1.5,
                  p: 3,
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                    m: 1.5,
                  }}
                >
                  <TextField
                    defaultValue=""
                    fullWidth
                    onChange={handleQueryChange}
                    inputProps={{ ref: queryRef }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon fontSize="small" />
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Search reportedUsers"
                  />
                </Box>
              </Box> */}

              <ReportedUsersTable
                isFetching={isFetching}
                reportedUsers={paginatedreportedUsers}
                reports={reports}
                handleChangeDisplayedReports={handleChangeDisplayedReports}
                reportedUserCount={reportedUsers?.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
                rowsPerPage={rowsPerPage}
                page={page}
                blockTable={true}
              />
            </>
          ) : isFetching ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h4>No users blocked yet</h4>
            </Box>
          )}
        </Card>
      </Container>
    </Box>
  );
}

export default BlockedUsers;
