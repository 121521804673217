import axios from 'axios';
const API_URL = process.env.REACT_APP_SERVER_URL;



export const login = async (data) => {
    const res = await axios.post(`${API_URL}/admin/signin`, data);
    return res.data
}

export const register = async (data) => {
    const res = await axios.post(`${API_URL}/admin/signup`, data);
    return res.data
}