
import { Routes, Route } from 'react-router-dom';
import Layout from './layout';
import { routes } from './utils/routes';
import AuthProvider from './auth';
import Login from './auth/login';
import Register from './auth/register';
import { Toaster } from 'react-hot-toast';
import Welcome from './pages/home';

function App() {
  return (
    <>
      <Toaster />
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/" element={<Welcome />} />
        <Route exact path="/register" element={<Register />} />
      </Routes>
      <AuthProvider>
        <Routes>
          {
            routes.map(route => (
              <>
                <Route exact path={route.path} element={
                  <Layout>
                    <route.element />
                  </Layout>

                } />
                {
                  route?.children?.map(child => (
                    <Route exact path={`${route.path}${child.path}`} element={<Layout>
                      <child.element />
                    </Layout>} />
                  ))
                }
              </>
            ))
          }
        </Routes>
      </AuthProvider>
    </>

  );
}

export default App;
