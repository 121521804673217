import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
  Card,
  Container,
  Typography,
} from "@mui/material";
import toast from "react-hot-toast";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Logo from "../layout/common/logo";
import { login } from "../_api";
import { Link, useNavigate } from "react-router-dom";
import { isAuthenticated } from "./auth";

const Login = () => {
  let token = localStorage.getItem("token");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .max(255)
        .required("Email is required"),
      password: Yup.string().max(255).required("Password is required"),
    }),
    onSubmit: async (values, helpers) => {
      toast.loading("Please wait...");
      const formData = new FormData();
      formData.append("username", values.email);
      formData.append("password", values.password);
      try {
        const res = await login(formData);
        toast.dismiss();
        localStorage.setItem("token", res.access_token);
        navigate("/events");
      } catch (err) {
        toast.dismiss();
        helpers.setErrors({
          submit:
            err.status == 401
              ? "User name or password in incorrect"
              : "Something went wrong, please try again",
        });
      }
    },
  });
  useEffect(() => {
    if (isAuthenticated(token)) {
      navigate("/events");
    }
  }, [token]);
  return (
    <>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) => theme.palette.common.bodybackground,
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: "60px",
              md: "120px",
            },
          }}
        >
          <Card elevation={16} sx={{ p: 4 }}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Logo
                sx={{
                  height: 40,
                  width: 40,
                }}
              />
              <Typography color="textSecondary" sx={{ mt: 2 }} variant="body2">
                Sign in on the admin platform
              </Typography>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 3,
              }}
            >
              <form noValidate onSubmit={formik.handleSubmit}>
                <TextField
                  autoFocus
                  error={Boolean(formik.touched.email && formik.errors.email)}
                  fullWidth
                  helperText={formik.touched.email ? formik.errors.email : ""}
                  label="Email Address"
                  margin="normal"
                  name="email"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  type="email"
                  value={formik.values.email}
                />
                <FormControl variant="outlined" fullWidth sx={{ mt: 2 }}>
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    shrink={true}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    error={Boolean(
                      formik.touched.password && formik.errors.password
                    )}
                    label="Password"
                    margin="none"
                    name="password"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={(event) => {
                            event.preventDefault();
                          }}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {formik.errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>
                      {formik.errors.submit}
                    </FormHelperText>
                  </Box>
                )}
                <Box sx={{ mt: 2 }}>
                  <Button
                    disabled={formik.isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Log In
                  </Button>
                  <Button
                    component={Link}
                    to="/register"
                    fullWidth
                    size="large"
                    variant="text"
                    sx={{ mt: 2 }}
                  >
                    Sign Up ?
                  </Button>
                </Box>
              </form>
            </Box>
            {/* <Divider sx={{ my: 3 }} /> */}
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
