import { Box, Button, Typography } from "@mui/material";
import React from "react";
import CategoryForm from "../../components/category/form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function EditCategory() {
  const navigate = useNavigate();
  return (
    <Box sx={{ marginX: "10px" }}>
      <Button
        startIcon={<ArrowBackIcon />}
        variant="text"
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <Typography variant="h2" color="initial">
        Edit Category
      </Typography>
      <CategoryForm isEdit={true} />
    </Box>
  );
}

export default EditCategory;
