import axios from 'axios';
const API_URL = process.env.REACT_APP_SERVER_URL + "/admin/city";

export const createCity = async (data) => {
    const res = await axios.post(`${API_URL}/create`, data);
    return res.data
}

export const updateCity = async (id, data) => {
    const res = await axios.put(`${API_URL}/update_city/${id}`, data);
    return res.data
}
export const getCity = async (id) => {
    const res = await axios.get(`${API_URL}/get_city/${id}`);
    return res.data
}

export const getAllCities = async () => {
    const res = await axios.get(`${API_URL}/all_cities`);
    return res.data
}

export const deletecity = async (id) => {
    const res = await axios.delete(`${API_URL}/${id}`);
    return res.data
}