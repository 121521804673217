import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Box,
  FormHelperText,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { uploadS3Media } from "../../_api/S3Services";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import {
  createCategory,
  getCategory,
  updateCategory,
} from "../../_api/category";
import { useQuery, useQueryClient } from "react-query";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 characters required")
    .required("Required"),
  icon: Yup.string().required("Required"),
});

const CategoryForm = ({ isEdit }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [isUploading, setIsUploading] = useState(false);
  const [icon, setIcon] = useState("");

  const { isLoading, data: category } = useQuery(
    ["get-category", id],
    () => getCategory(id),
    { enabled: isEdit }
  );
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    icon: "",
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      toast.loading("Please wait...");
      try {
        isEdit
          ? await updateCategory(id, { text: values.name, icon: values.icon })
          : await createCategory({ text: values.name, icon: values.icon });
        toast.dismiss();

        isEdit
          ? toast.success("Category Updated")
          : toast.success("Category Added");
        queryClient.invalidateQueries("get-category");
        navigate(-1);
      } catch (err) {
        toast.dismiss();
        toast.error(err?.message);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!isLoading && category) {
      formik.setFieldValue("name", category.text);
      formik.setFieldValue("icon", category.icon);
      setIcon(category.icon);
    }
  }, [isLoading, category]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h4" gutterBottom>
        Event Form
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          {formik.values.icon && (
            <img
              width={"50%"}
              style={{ maxHeight: "300px", maxWidth: "300px" }}
              src={icon}
              alt="Uploaded Icon"
            />
          )}
          <input
            id="icon"
            name="icon"
            type="file"
            accept=".svg"
            onChange={async (event) => {
              setIsUploading(true);
              const res = await uploadS3Media(event.target.files[0]);
              formik.setFieldValue(
                "icon",
                process.env.REACT_APP_S3_BUCKET_LINK + res
              );
              setIcon(process.env.REACT_APP_S3_BUCKET_LINK + res);
              setIsUploading(false);
            }}
            onBlur={formik.handleBlur}
            hidden
          />
          <label htmlFor="icon">
            <Button
              startIcon={<FileUploadIcon />}
              color="primary"
              variant="outlined"
              component="span"
              fullWidth
              sx={{ py: 5 }}
            >
              {formik.values.icon ? "Update Icon" : "Upload Icon"}
            </Button>
          </label>
          {formik.errors.icon && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{formik.errors.icon}</FormHelperText>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={formik.isSubmitting || isUploading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CategoryForm;
