import React, { useEffect, useState, useCallback } from 'react';
import {
    GoogleMap,
    Marker,
    useLoadScript,
} from '@react-google-maps/api';

function GoogleMaps({ selectedPosition }) {

    const [currentPosition, setCurrentPosition] = useState(null);
    const mapContainerStyle = {
        height: '320px',
        width: '100%',
        borderRadius: "15px"
    };

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        libraries: ['places'],
    });


    const center = selectedPosition || currentPosition || { lat: 0, lng: 0 };

    const onLoad = useCallback((map) => {
        if (selectedPosition) {
            map.panTo(selectedPosition);
        } else if (currentPosition) {
            map.panTo(currentPosition);
        }
    }, [selectedPosition, currentPosition]);

    // Get user's current location
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setCurrentPosition({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                },
                () => {
                    console.error('Error fetching user location');
                }
            );
        }
    }, []);



    if (loadError) return 'Error loading maps';
    if (!isLoaded) return 'Loading Maps';
    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={14}
            center={center}
            onLoad={onLoad}
        >
            {selectedPosition && (
                <Marker position={selectedPosition} />
            )}
            {!selectedPosition && currentPosition && (
                <Marker position={currentPosition} />
            )}
        </GoogleMap>
    )
}

export default GoogleMaps