import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { routes } from "../../utils/routes";
import { Link, useLocation } from "react-router-dom";

function SidebarItems({ open }) {
  const location = useLocation();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          // justifyContent: "center",
          alignItems: "center",
          padding: 2.7,
          background: (theme) => theme.palette.common.sideBarBackground,
        }}
      >
        <img
          src="/assets/logo/logo.png"
          alt="Logo"
          style={{
            width: "110px",
            height: "auto",
            transition: "width 0.2s ease-in-out",
          }}
        />
      </Box>
      <List
        sx={{ background: (theme) => theme.palette.common.sideBarBackground }}
      >
        {routes.map((item) => {
          let isActive = location.pathname?.includes(item.path);
          {
            return (
              <ListItem
                key={item.path}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  component={Link}
                  to={item.path}
                  sx={[
                    {
                      minHeight: 48,
                      px: 2.5,
                    },
                    open
                      ? {
                          justifyContent: "initial",
                        }
                      : {
                          justifyContent: "center",
                        },
                  ]}
                >
                  <ListItemIcon
                    sx={[
                      {
                        color: (theme) =>
                          isActive
                            ? theme.palette.primary.main
                            : theme.palette.primary.black,
                        minWidth: 0,
                        justifyContent: "center",
                      },
                      open
                        ? {
                            mr: 3,
                          }
                        : {
                            mr: "auto",
                          },
                    ]}
                  >
                    {<item.icon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    primaryTypographyProps={{
                      sx: [
                        open
                          ? {
                              color: (theme) =>
                                isActive
                                  ? theme.palette.primary.main
                                  : theme.palette.common.black,
                              opacity: 1,
                              fontWeight: 500, // Apply fontWeight here
                            }
                          : {
                              opacity: 0,
                            },
                      ],
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          }
        })}
      </List>
    </>
  );
}

export default SidebarItems;
