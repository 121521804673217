import { Box, Grid, Typography } from "@mui/material";
import ReplyIcon from "@mui/icons-material/Reply";

const UserCard = () => {
  return (
    <Box
      borderRadius="8px"
      // width="100%"
      sx={{
        padding: "10px",
        marginY: "20px",
        backgroundColor: "common.lightBlue",
      }}
    >
      <Box
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // width: "90%",
        }}
      >
        <Box
          item
          gap={1}
          sx={{ display: "flex", width: { md: "150px", sm: "300px" } }}
        >
          <Box
            component="img"
            sx={{
              width: 35,
              height: 35,
              borderRadius: "50%",
              boxShadow: 3,
              objectFit: "cover",
              marginTop: "6px",
            }}
            alt="bank card"
            src="/bank_card.webp"
          ></Box>
          <Box width="100%">
            <Typography
              variant="body2"
              color="common.black"
              sx={{ fontWeight: "bold", fontSize: "14px" }}
            >
              Samantha 24
            </Typography>
            <Typography
              color="common.black"
              // maxWidth="80%"
              variant="body2"
              sx={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                opacity: "50%",
              }}
            >
              Lorem Ipsum hfjekggficasujihduifueifiwuiwefu
            </Typography>
            <Typography
              m={0}
              maxWidth="80px"
              color="common.disabled"
              variant="body2"
              sx={{ fontSize: "10px" }}
            >
              3 Mins ago
            </Typography>
          </Box>
        </Box>
        <Box>
          <ReplyIcon
            fontSize="medium"
            sx={{
              color: "common.black",
              backgroundColor: "common.white",
              padding: "8px",
              borderRadius: "50%",
              marginBottom: "10px",
              cursor: "pointer",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default UserCard;
