import React from "react";
import UserDetailScreen from "../../components/users/view";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getUser } from "../../_api/users";
import { CircularProgress } from "@mui/material";

function ViewUser() {
  const { id } = useParams();
  const { isLoading, data: User } = useQuery(
    ["get-User", id],
    () => getUser(id),
    {
      staleTime: 0,
    }
  );

  console.log(User);
  return isLoading ? (
    <CircularProgress />
  ) : (
    <UserDetailScreen userData={User} />
  );
}

export default ViewUser;
