
import { isExpired } from "react-jwt";
export const isAuthenticated = (token) => {
    // return true
    if (token === undefined) {
        return false;
    }
    if (token === "NULL") {
        return false;
    }
    if (token === null) {
        return false;
    }
    return true
    // return !isExpired(token);
};