import { useState, useRef } from "react";
import { useQuery } from "react-query";
import {
    Box,
    Button,
    Card,
    Container,
    Divider,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { getAllCountries } from "../../_api/country";
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { CountryTable } from "../../components/country/table";

const applyFilters = (data, filters) => {
    return (
        data?.filter((item) => {
            if (filters.query) {
                let queryMatched = false;
                const properties = ["name"];

                properties.forEach((property) => {
                    if (
                        String(item.name)
                            .toLowerCase()
                            .includes(filters.query.toLowerCase())
                    ) {
                        queryMatched = true;
                    }
                });
                if (!queryMatched) {
                    return false;
                }
            }
            return true;
        })
    )
}

const Country = () => {
    const { isLoading, data: allCountries } = useQuery("get-countries", getAllCountries);
    const queryRef = useRef(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [filters, setFilters] = useState({
        query: "",
        hasAcceptedMarketing: null,
        isProspect: null,
        isReturning: null,
    });

    const applyPagination = (allCountries, page, rowsPerPage) =>
        allCountries?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };

    const handleQueryChange = (event) => {
        event.preventDefault();
        setFilters((prevState) => ({
            ...prevState,
            query: queryRef.current?.value,
        }));
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const filteredUsers = applyFilters(allCountries, filters);
    const paginatedCountries = applyPagination(filteredUsers, page, rowsPerPage);

    return (
        <>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    py: 8,
                }}
            >
                <Container maxWidth="xl">
                    <Box sx={{ mb: 4 }}>
                        <Grid container justifyContent="space-between" spacing={3}>
                            <Grid item>
                                <Typography variant="h4" color="primary">Countries</Typography>
                            </Grid>
                            <Grid item>
                                <Link to="/countries/add">
                                    <Button
                                        startIcon={<AddIcon />}
                                        variant="contained"
                                    >
                                        Add
                                    </Button>
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Card>
                        {allCountries?.length ? (
                            <>
                                <Box
                                    sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        flexWrap: "wrap",
                                        m: -1.5,
                                        p: 3,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            m: 1.5,
                                        }}
                                    >
                                        <TextField
                                            defaultValue=""
                                            fullWidth
                                            onChange={handleQueryChange}
                                            inputProps={{ ref: queryRef }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <SearchIcon fontSize="small" />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            placeholder="Search Countries"
                                        />
                                    </Box>
                                </Box>

                                <CountryTable
                                    isLoading={isLoading}
                                    countries={paginatedCountries}
                                    countryCount={allCountries?.length}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleRowsPerPageChange}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                />
                            </>
                        ) : isLoading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <h4>
                                    Countries are not added yet. Please add by clicking "+ Add" button.
                                </h4>
                            </Box>
                        )}
                    </Card>
                </Container>
            </Box>
        </>
    );
};



export default Country;
