import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box, Typography } from "@mui/material";

function Quill({ formik }) {
  return (
    <Box
      sx={{
        ".ql-editor": {
          color: "common.black",
        },
        ".ql-container": {
          height: "150px",
        },
      }}
    >
      <Typography
        sx={{ color: "common.black", marginLeft: "10px", marginBottom: "3px" }}
      >
        Description
      </Typography>
      <ReactQuill
        theme="snow"
        style={{ color: "common.black" }}
        name="programDescription"
        label="Description"
        fullWidth
        variant="outlined"
        onChange={(value) => formik.setFieldValue("programDescription", value)}
        value={formik.values.programDescription}
        error={
          formik.touched.programDescription &&
          Boolean(formik.errors.programDescription)
        }
        helperText={
          formik.touched.programDescription && formik.errors.programDescription
        }
      />
      {formik.touched.programDescription &&
        formik.errors.programDescription && (
          <Typography
            sx={{ color: "common.red", fontSize: "12px", marginLeft: "10px" }}
          >
            {formik.errors.programDescription}
          </Typography>
        )}
    </Box>
  );
}

export default Quill;
