import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Welcome() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/events");
  }, []);
  return <div>Redirecting...</div>;
}

export default Welcome;
