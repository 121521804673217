import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  FormHelperText,
  Box,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { uploadS3Media } from "../../_api/S3Services";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { createCity, getCity, updateCity } from "../../_api/city";
import { useQuery, useQueryClient } from "react-query";
import { getAllCountries } from "../../_api/country";

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 characters required")
    .required("Required"),
  image: Yup.string()
    .min(2, "Minimum 2 characters required")
    .required("Please upload an image"),
  description: Yup.string()
    .min(5, "Minimum 2 characters required")
    .required("Required"),
  country_id: Yup.number("Should be a valid id").required("Required"),
});

const CityForm = ({ isEdit }) => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [isUploading, setIsUploading] = useState(false);
  const { isLoading, data: city } = useQuery(
    ["get-city", id],
    () => getCity(id),
    { enabled: isEdit }
  );

  const { isLoading: countriesLoading, data: allCountries } = useQuery(
    "get-countries",
    getAllCountries
  );
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    image: "",
    description: "",
    country_id: null,
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      toast.loading("Please wait...");
      try {
        let payload = {
          name: values.name,
          image: values.image,
          description: values.description,
          country_id: values.country_id,
        };
        isEdit ? await updateCity(id, payload) : await createCity(payload);
        toast.dismiss();
        toast.success("City Added");
        queryClient.invalidateQueries("get-cities");
        navigate(-1);
      } catch (err) {
        toast.dismiss();
        toast.error(err?.message);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!isLoading && city) {
      formik.setFieldValue("name", city.name);
      formik.setFieldValue("image", city.image);
      formik.setFieldValue("description", city.description);
      formik.setFieldValue("country_id", city.country_id);
    }
  }, [isLoading, city]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h4" gutterBottom>
        Event Form
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="name"
            label="Name"
            fullWidth
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            name="country_id"
            label="Select Country"
            fullWidth
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.country_id}
            error={
              formik.touched.country_id && Boolean(formik.errors.country_id)
            }
            helperText={formik.touched.country_id && formik.errors.country_id}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
          >
            <option value="">Select Country</option>
            {countriesLoading && (
              <option disabled value="">
                <CircularProgress />
              </option>
            )}
            {allCountries?.map((country) => (
              <option value={country.id}>{country.name}</option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline
            minRows={6}
            name="description"
            label="Description"
            fullWidth
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.description}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
        </Grid>
        <Grid item xs={12}>
          {formik.values.image && (
            <img
              width={"50%"}
              style={{ maxHeight: "300px", maxWidth: "300px" }}
              src={formik.values.image}
              alt="Uploaded File"
            />
          )}
          <input
            id="image"
            name="image"
            type="file"
            accept=".jpg, .jpeg, .png, .gif, .bmp, .svg"
            onChange={async (event) => {
              setIsUploading(true);
              const res = await uploadS3Media(event.target.files[0]);
              formik.setFieldValue(
                "image",
                process.env.REACT_APP_S3_BUCKET_LINK + res
              );
              setIsUploading(false);
            }}
            onBlur={formik.handleBlur}
            hidden
          />
          <label htmlFor="image">
            <Button
              startIcon={<FileUploadIcon />}
              color="primary"
              variant="outlined"
              component="span"
              fullWidth
              sx={{ py: 5 }}
            >
              {formik.values.image ? "Update Image" : "Upload Image"}
            </Button>
          </label>
          {formik.errors.image && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>{formik.errors.image}</FormHelperText>
            </Box>
          )}
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            disabled={formik.isSubmitting || isUploading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CityForm;
