import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Input,
  IconButton,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { getEvent, updateEvent, createEvent } from "../../_api/events";
import { getAllCities } from "../../_api/city";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import moment from "moment";
import GoogleMaps from "./GoogleMaps";
import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import { uploadS3Media } from "../../_api/S3Services";
import { getAllCategories } from "../../_api/category";
import CloseIcon from "@mui/icons-material/Close";
import Quill from "./quill";

const initialValues = {
  venue: "",
  address: "",
  latitude: "",
  longitude: "",
  radius: "",
  startTime: "",
  endTime: "",
  city_id: "",
  maximumDates: "",
  programDescription: "",
  event_images: [],
  minimum_age: null,
  url: "",
  category_id: null,
};
const validationSchema = Yup.object().shape({
  venue: Yup.string()
    .min(2, "Minimum 2 characters required")
    .required("Required"),
  address: Yup.string()
    .min(2, "Minimum 2 characters required")
    .required("Required"),
  latitude: Yup.number().required("Required"),
  longitude: Yup.number().required("Required"),
  radius: Yup.number().required("Required"),
  startTime: Yup.date()
    .required("Required")
    .test("startTime", "Start time must be before end time", function (value) {
      const { endTime } = this.parent;
      if (!value || !endTime) return true; // Skip validation if either field is empty
      return new Date(value) < new Date(endTime);
    }),
  endTime: Yup.date()
    .required("Required")
    .test("endTime", "End time must be after start time", function (value) {
      const { startTime } = this.parent;
      if (!value || !startTime) return true; // Skip validation if either field is empty
      return new Date(value) > new Date(startTime);
    }),
  maximumDates: Yup.number().required("Required"),
  programDescription: Yup.string().required("Required"),
  city_id: Yup.string().required("Required"),
  event_images: Yup.array()
    .min(4, "Please select a minimum of 4 images") // Minimum length (greater than 4)
    .max(8, "Please select upto 8 images") // Maximum length (less than 8)
    .required("Images are Required"),
  minimum_age: Yup.number()
    .required("Required")
    .min(0, "Age cannot be negative"),
  url: Yup.string().required("Required"),
});

const EventForm = ({ isEdit }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const [selectedPosition, setSelectedPosition] = useState(null);

  const [images, setImages] = React.useState([]);
  const [fileError, setFileError] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const { isLoading: categoriesLoading, data: allCategories } = useQuery(
    "get-categories",
    getAllCategories,
    {
      staleTime: 0,
    }
  );

  const updateImages = (incommingFiles) => {
    const errors = [];

    // Check file size and type
    incommingFiles.forEach((file) => {
      if (file.size > 2 * 1024 * 1024) {
        errors.push(`${file.name} exceeds 2MB`);
        setFileError(`${file.name} exceeds 2MB`);
      }
    });

    if (errors.length > 0) {
      return;
    }

    setImages(incommingFiles);
    setFileError(null);
  };

  const removeImage = (id) => {
    const filteredFiles = images.filter((image) => image.id !== id);
    setImages(filteredFiles);
  };

  const handleDeleteImage = (index) => {
    const filteredImages = imageUrls.filter((image, i) => i !== index);
    setImageUrls(filteredImages);
    formik.setFieldValue("event_images", filteredImages);
  };

  const handleImagesUpload = async () => {
    if (images.length + imageUrls.length > 8) {
      setFileError("You can only have upto 8 images total");
      return;
    }

    const newImages = images.map(async (image) => {
      const name = await uploadS3Media(image.file);
      return process.env.REACT_APP_S3_BUCKET_LINK + name;
    });

    const urls = await Promise.all(newImages);
    const finalUrls = [...imageUrls, ...urls];
    formik.setFieldValue("event_images", finalUrls);

    setImageUrls(finalUrls);
    setImages([]);
  };

  const { isLoading, data: event } = useQuery(
    ["get-event", id],
    () => getEvent(id),
    {
      enabled: isEdit,
      staleTime: 0,
    }
  );
  console.log(event);
  const { isLoading: citiesLoading, data: cities } = useQuery(
    "get-cities",
    getAllCities
  );

  // const formatForInput = (dateString) => {
  //   // Convert API datetime to input format (YYYY-MM-DDThh:mm)
  //   return dateString ? moment(dateString).format("YYYY-MM-DDTHH:mm") : "";
  // };

  // const formatForAPI = (dateString) => {
  //   // Convert input datetime to API format (YYYY-MM-DD HH:mm:ss)
  //   return dateString ? moment(dateString).format("YYYY-MM-DD HH:mm:ss") : "";
  // };

  // const buildPayload = (values) => {
  //   const start_time = formatForAPI(values.startTime);
  //   return {
  //     ...values,
  //     startTime: start_time,
  //     date: start_time,
  //     endTime: formatForAPI(values.endTime),
  //   };
  // };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      toast.loading("Please wait...");
      try {
        let payload = {
          ...values,
          date: moment(values.startTime).utc().format("YYYY-MM-DD HH:mm:ss"),
          startTime: moment(values.startTime)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
          endTime: moment(values.endTime).utc().format("YYYY-MM-DD HH:mm:ss"),
        };

        if (isEdit) {
          await updateEvent(id, payload);
        } else {
          await createEvent(payload);
        }
        toast.dismiss();
        toast.success(isEdit ? "Event Updated" : "Event Added");
        queryClient.invalidateQueries("get-events");
        navigate(-1);
      } catch (err) {
        toast.dismiss();
        toast.error(err?.message);
      } finally {
        formik.setSubmitting(false);
      }
    },
  });

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place) => {
      const formattedAddress = place?.formatted_address || "";
      const latitude = place?.geometry?.location?.lat();
      const longitude = place?.geometry?.location?.lng();

      // Update Formik values
      formik.setFieldValue("address", formattedAddress);
      formik.setFieldValue("latitude", latitude);
      formik.setFieldValue("longitude", longitude);
      setSelectedPosition({
        lat: latitude,
        lng: longitude,
      });
    },
    options: {
      types: [], // Include all place types
    },
  });

  useEffect(() => {
    if (!isLoading && event) {
      formik.setValues({
        venue: event.venue,
        address: event.address,
        latitude: event.latitude,
        longitude: event.longitude,
        radius: event.radius,
        startTime: moment
          .utc(event.startTime)
          .local()
          .format("YYYY-MM-DDTHH:mm"),
        endTime: moment.utc(event.endTime).local().format("YYYY-MM-DDTHH:mm"),
        maximumDates: event.maximumDates,
        programDescription: event.programDescription,
        city_id: event.city_id,
        event_images: event.event_images,
        minimum_age: event.minimum_age,
        category_id: event.category_id,
        url: event.url,
      });
      console.log(formik.values);

      setImageUrls(event.event_images);
    }
  }, [isLoading, event]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography variant="h4" gutterBottom>
        Event Form
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="venue"
            label="Enter Venue"
            fullWidth
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.venue}
            error={formik.touched.venue && Boolean(formik.errors.venue)}
            helperText={formik.touched.venue && formik.errors.venue}
          />
        </Grid>
        <Grid item xs={12} textAlign="center">
          <Dropzone onChange={updateImages} value={images} accept="image/*">
            {images?.map((image) => (
              <FileMosaic {...image} preview onDelete={removeImage} />
            ))}
          </Dropzone>
          <Typography variant="body1" color="common.red">
            {fileError}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleImagesUpload}
          >
            Upload
          </Button>
        </Grid>

        {imageUrls?.map((image, index) => {
          return (
            <Grid item xs={12} sm={4}>
              <Box
                sx={{
                  position: "relative",
                  borderRadius: "8px",
                  height: "100%",
                  width: "100%",
                }}
              >
                <img
                  src={image}
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    borderRadius: "8px",
                  }}
                />
                {isEdit && (
                  <CloseIcon
                    fontSize="medium"
                    sx={{
                      color: "common.black",
                      cursor: "pointer",
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      zIndex: 1,
                      backgroundColor: "common.white",
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      handleDeleteImage(index);
                    }}
                  />
                )}
              </Box>
            </Grid>
          );
        })}
        <Grid item xs={12} textAlign="center">
          {formik.touched.event_images && formik.errors.event_images ? (
            <Typography variant="body1" color="common.red">
              {formik.errors.event_images}
            </Typography>
          ) : null}
        </Grid>

        <Grid item xs={12}>
          <TextField
            inputRef={ref}
            name="address"
            onBlur={formik.handleBlur}
            value={formik.values.address}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </Grid>
        <Grid item xs={12}>
          <GoogleMaps
            setSelectedPosition={setSelectedPosition}
            selectedPosition={selectedPosition}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="latitude"
            label="Location Latitude"
            fullWidth
            variant="outlined"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.latitude}
            error={formik.touched.latitude && Boolean(formik.errors.latitude)}
            helperText={formik.touched.latitude && formik.errors.latitude}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="longitude"
            label="Location Longitude"
            fullWidth
            variant="outlined"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.longitude}
            error={formik.touched.longitude && Boolean(formik.errors.longitude)}
            helperText={formik.touched.longitude && formik.errors.longitude}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            name="radius"
            label="Limit Radius (m)"
            fullWidth
            variant="outlined"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.radius}
            error={formik.touched.radius && Boolean(formik.errors.radius)}
            helperText={formik.touched.radius && formik.errors.radius}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            InputLabelProps={{ shrink: true }}
            name="startTime"
            label="Start Time"
            fullWidth
            variant="outlined"
            type="datetime-local"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            // console.log(e.target.value);
            // formik.setFieldValue(
            //   "startTime",
            //   moment(e.target.value).utc().local()
            // );

            value={formik.values.startTime}
            error={formik.touched.startTime && Boolean(formik.errors.startTime)}
            helperText={formik.touched.startTime && formik.errors.startTime}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="endTime"
            label="End Time"
            InputLabelProps={{ shrink: true }}
            fullWidth
            variant="outlined"
            type="datetime-local"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.endTime}
            error={formik.touched.endTime && Boolean(formik.errors.endTime)}
            helperText={formik.touched.endTime && formik.errors.endTime}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            name="city_id"
            label="Select City"
            fullWidth
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.city_id}
            error={formik.touched.city_id && Boolean(formik.errors.city_id)}
            helperText={formik.touched.city_id && formik.errors.city_id}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
          >
            <option value="">Select City</option>
            {citiesLoading && (
              <option disabled value="">
                <CircularProgress />
              </option>
            )}
            {cities?.map((city_id) => (
              <option value={city_id.id}>{city_id.name}</option>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="minimum_age"
            label="Minimum Age"
            fullWidth
            variant="outlined"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.minimum_age}
            InputLabelProps={{ shrink: true }}
            error={
              formik.touched.minimum_age && Boolean(formik.errors.minimum_age)
            }
            helperText={formik.touched.minimum_age && formik.errors.minimum_age}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="maximumDates"
            label="Maximum Dates"
            fullWidth
            variant="outlined"
            type="number"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.maximumDates}
            error={
              formik.touched.maximumDates && Boolean(formik.errors.maximumDates)
            }
            helperText={
              formik.touched.maximumDates && formik.errors.maximumDates
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            select
            name="category_id"
            label="Event Category"
            fullWidth
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.category_id}
            error={
              formik.touched.category_id && Boolean(formik.errors.category_id)
            }
            helperText={formik.touched.category_id && formik.errors.category_id}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
          >
            <option value={null}>Select Event Category</option>
            {!categoriesLoading &&
              allCategories?.map((category) => {
                return <option value={category.id}>{category.text}</option>;
              })}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Venue Website URL"
            name="url"
            onBlur={formik.handleBlur}
            value={formik.values.url}
            onChange={formik.handleChange}
            fullWidth
            error={formik.touched.url && Boolean(formik.errors.url)}
            helperText={formik.touched.url && formik.errors.url}
          />
        </Grid>
        <Grid item xs={12}>
          {/* <TextField
            name="programDescription"
            label="Description"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.programDescription}
            error={
              formik.touched.programDescription &&
              Boolean(formik.errors.programDescription)
            }
            helperText={
              formik.touched.programDescription &&
              formik.errors.programDescription
            }
          /> */}
          <Quill formik={formik} />
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EventForm;
