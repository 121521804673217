import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Button,
  Stack,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const DisplayData = ({ label, value }) => {
  return (
    <>
      <Box sx={{ display: "flex", gap: 5 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
          {label}{" "}
        </Typography>
        <Typography variant="subtitle1">{value}</Typography>
      </Box>
      <Divider sx={{ margin: "10px 0" }} />
    </>
  );
};

const UserDetailScreen = ({ userData }) => {
  const navigate = useNavigate();

  return (
    <Box sx={{ padding: 2 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        variant="text"
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <Card variant="outlined">
        <CardHeader
          sx={{ color: (theme) => theme.palette.primary.main }}
          title="User Details"
        />
        <CardContent>
          <DisplayData label="First Name" value={userData?.firstName} />
          <DisplayData label="Email" value={userData?.email} />
          <DisplayData label="Country" value={userData?.country} />
          <DisplayData label="Phone Number" value={userData?.phoneNumber} />
          <DisplayData label="Description" value={userData?.description} />
          <DisplayData label="Height" value={userData?.height} />
          <DisplayData label="Civility" value={userData?.civility} />
          <DisplayData
            label="Education Level"
            value={userData?.education_level}
          />
          <DisplayData label="Qualification" value={userData?.qualification} />
          <DisplayData
            label="Employment Status"
            value={userData?.job_situation}
          />
          <DisplayData
            label="Employed Industry"
            value={userData?.employed_industry}
          />
          <DisplayData label="Ethnicity" value={userData?.ethnic_affiliation} />
          <DisplayData label="Religion" value={userData?.religion} />
          <DisplayData
            label="Political Orientation"
            value={userData?.political_orientation}
          />
          <DisplayData label="Zodiac Sign" value={userData?.zodiac_sign} />
          <DisplayData
            label="Civility interested In"
            value={userData?.civility_intrested_in}
          />
          <DisplayData label="Smokes" value={userData?.smoke} />
          <DisplayData label="Drinks alcohol" value={userData?.drink_alcohol} />
          <DisplayData label="Uses cannibas" value={userData?.use_cannabis} />
          <DisplayData
            label="Relationship Preference"
            value={userData?.relationship_type}
          />
          <DisplayData
            label="Partner Minimum Age"
            value={userData?.partner_min_age}
          />
          <DisplayData
            label="Partner Maximum Age"
            value={userData?.partner_max_age}
          />

          <Box sx={{ display: "flex", alignItems: "center", gap: 10 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
              Spoken Languages
            </Typography>

            <Stack
              direction="row"
              spacing={1}
              sx={{ flexWrap: "wrap", gap: 1 }}
            >
              {userData?.spoken_languages?.map((language) => (
                <Chip
                  key={language}
                  label={language}
                  sx={{
                    borderRadius: "16px",
                    border: "1px solid #ccc",
                    backgroundColor: "transparent",
                    fontSize: "16px",
                  }}
                />
              ))}
            </Stack>
          </Box>
          <Divider sx={{ margin: "10px 0" }} />
          <Box sx={{ display: "flex", alignItems: "center", gap: 10 }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
              Interests
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              sx={{ flexWrap: "wrap", gap: 1 }}
            >
              {userData?.interests?.map((interest) => (
                <Chip
                  key={interest}
                  label={interest}
                  sx={{
                    borderRadius: "16px",
                    border: "1px solid #ccc",
                    backgroundColor: "transparent",
                    fontSize: "16px",
                  }}
                />
              ))}
            </Stack>
          </Box>
          <Divider sx={{ margin: "10px 0" }} />
          <Grid container spacing={2} marginY={2}>
            {userData?.profile_images?.map((image) => {
              return (
                <Grid item xs={12} sm={3}>
                  <Box
                    sx={{
                      position: "relative",
                      borderRadius: "8px",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <img
                      src={image}
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default UserDetailScreen;
