// ... existing code ...

function formatTime(dateString) {
  const date = new Date(dateString);
  return date
    .toLocaleString("en-US", {
      hour: "numeric",
      //   minute: "2-digit",
      hour12: true,
    })
    .toUpperCase()
    .replace(" ", "");
}

export function getTimeRange(startTime, endTime) {
  if (!startTime || !endTime) return "";
  const formattedStart = formatTime(startTime);
  const formattedEnd = formatTime(endTime);
  return `${formattedStart} - ${formattedEnd}`;
}

export function formatDate(dateString) {
  const date = new Date(dateString);
  console.log(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en-US", { month: "long" });
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
}

// ... rest of the code ...
