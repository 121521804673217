import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box, Container, Typography, Grid, Card } from "@mui/material";
import BankCard from "../../components/dashboard/bankCard";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import UserCard from "../../components/dashboard/userCard";
import { useQuery } from "react-query";
import { getAllEvents } from "../../_api/events";
import parse from "html-react-parser";
import UserFooter from "../../components/dashboard/userFooter";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getTimeRange, formatDate } from "./utils";

function Dashboard() {
  const { isLoading, data: events } = useQuery("get-events", getAllEvents);

  console.log(events);
  const swiperRef = useRef(null);

  const handleNext = () => {
    swiperRef.current?.slideNext();
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    // Responsive settings if needed
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    !isLoading && (
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // fontFamily: "Roboto, Arial, sans-serif",
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            {/* <Grid item xs={0} md={3}>
            <Box
              sx={{
                display: "flex",
                // flexWrap: "wrap",
                // gap: "20px",
                width: "100%",
                flexDirection: "column",
              }}
            ></Box>
          </Grid> */}
            <Grid item xs={12} md={7} sm={12}>
              <Box
                sx={{
                  display: "flex",
                  // flexWrap: "wrap",
                  // gap: "20px",
                  width: "100%",
                }}
              >
                <Grid container spacing={2}>
                  <Grid
                    item
                    sm={6}
                    md={6}
                    xs={12}
                    sx={{ paddingLeft: "8px !important" }}
                  >
                    <BankCard />
                  </Grid>
                  <Grid
                    item
                    sm={6}
                    md={6}
                    xs={12}
                    sx={{ paddingLeft: "8px !important" }}
                  >
                    <BankCard />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ paddingLeft: "8px !important" }}
                  >
                    <Card>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: "30px",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="body2"
                              color="common.black"
                              sx={{ fontWeight: "bold", fontSize: "14px" }}
                            >
                              Upcoming Events
                            </Typography>
                            <Link>
                              <Typography
                                color="common.black"
                                variant="body2"
                                sx={{ opacity: "50%", fontSize: "10px" }}
                              >
                                Show Details
                              </Typography>
                            </Link>
                          </Box>
                          <Link>
                            <Box
                              gap={1}
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                color="common.black"
                                variant="body2"
                                sx={{ fontSize: "10px" }}
                              >
                                Next Event
                              </Typography>
                              <ArrowForwardIcon
                                fontSize="small"
                                sx={{ color: "common.black" }}
                              />
                            </Box>
                          </Link>
                        </Box>
                      </Box>

                      <Grid container spacing={2}>
                        <Grid
                          item
                          sm={12}
                          xs={12}
                          md={3}
                          lg={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              borderRadius: "8px",
                              height: "100%",
                              width: "100%",
                              textAlign: "center",
                              paddingY: "20px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <img
                              alt="test image"
                              src={events?.[0]?.event_images?.[1]}
                              style={{
                                objectFit: "cover",
                                width: "100%",
                                height: "100%",
                                borderRadius: "12px",
                                overflow: "hidden",
                              }}
                            />
                          </Box>
                          {/* <Typography color="common.blue" marginY={2}>
                          User bookmarked
                        </Typography> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={9} lg={10}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            paddingY="30px"
                          >
                            <Typography
                              variant="body2"
                              sx={{ opacity: "50%", fontSize: "10px" }}
                            >
                              {events?.[0]?.city?.name}
                            </Typography>
                            <Typography
                              // color="common.black"
                              // variant="h6"
                              // fontWeight="bold"
                              color="common.black"
                              sx={{ fontWeight: "bold", fontSize: "12px" }}
                            >
                              {events?.[0]?.category?.text}
                            </Typography>
                            <Box
                              mt={2}
                              mb={2}
                              sx={{
                                display: "flex",
                                // flexWrap: "wrap",
                                gap: "15px",
                                flexDirection: {
                                  md: "row",
                                  sm: "row",
                                  xs: "column",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  // flexWrap: "wrap",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <CalendarMonthIcon
                                  fontSize="5px"
                                  sx={{ color: "primary.main" }}
                                />
                                <Typography
                                  color="common.black"
                                  variant="body2"
                                  fontSize="10px"
                                >
                                  {" "}
                                  {formatDate(events?.[0]?.startTime)}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  // flexWrap: "wrap",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <WatchLaterOutlinedIcon
                                  fontSize="5px"
                                  sx={{ color: "primary.main" }}
                                />
                                <Typography
                                  color="common.black"
                                  variant="body2"
                                  fontSize="10px"
                                >
                                  {getTimeRange(
                                    events?.[0]?.startTime,
                                    events?.[0]?.endTime
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  // flexWrap: "wrap",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <RoomOutlinedIcon
                                  fontSize="5px"
                                  sx={{ color: "primary.main" }}
                                />
                                <Typography
                                  color="common.black"
                                  variant="body2"
                                  fontSize="10px"
                                >
                                  {parse(events?.[0]?.venue)}
                                </Typography>
                              </Box>
                            </Box>
                            <Typography color="common.blue" variant="body2">
                              {/* {parse(events?.[0]?.programDescription)} */}
                              Description of event in detail: lorem ipsum
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      <Typography
                        color="common.blue"
                        variant="body2"
                        marginLeft={{ md: "5px", xs: "0px" }}
                      >
                        User bookmarked
                      </Typography>
                      {/* <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Box
                          sx={{
                            width: "200px",
                            "& .slick-list": {
                              overflow: "hidden",
                            },
                            "& .slick-track": {
                              display: "flex",
                              width: "100% !important", // Ensure it takes full width
                            },
                            "& .slick-slide": {
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              px: 1,
                            },
                            "& .slick-prev, & .slick-next": {
                              zIndex: 1,
                              "&:before": {
                                color: "common.blue",
                              },
                            },
                          }}
                        >
                          <Slider {...sliderSettings}>
                            <Box
                              sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography>Hello world</Typography>
                            </Box> */}
                      {/* Add more slides as needed */}
                      {/* <Box
                              sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography>Hello world</Typography>
                            </Box>
                          </Slider>
                        </Box>
                      </Grid>
                    </Grid> */}

                      {/* <Grid container spacing={4}>
                          <Grid item md={6} xs={6} sm={6}>
                            <UserFooter />
                            <Typography>Hello world</Typography>
                          </Grid>
                          <Grid item md={6} xs={6} sm={6}>
                            <UserFooter />
                          </Grid>
                        </Grid> */}
                      {/* </SwiperSlide>
                      <SwiperSlide>
                        Slide1</SwiperSlide>
                      <SwiperSlide>Slide1</SwiperSlide>
                      <SwiperSlide>Slide1</SwiperSlide> */}

                      {/* </Grid> */}
                      {/* <SwiperSlide>
                        <Grid container spacing={4}>
                          <Grid item md={6} xs={6} sm={6}> */}
                      {/* <UserFooter /> */}
                      {/* <Typography>Hello world</Typography>
                          </Grid>
                          <Grid item md={6} xs={6} sm={6}> */}
                      {/* <UserFooter /> */}
                      {/* <Typography>Hello world</Typography>
                          </Grid>
                        </Grid>
                      </SwiperSlide> */}
                      {/* <ArrowForwardIcon
                        onClick={handleNext}
                        style={{
                          position: "absolute",
                          right: 6,
                          top: "50%",
                          zIndex: 10,
                          transform: "translateY(-50%)",

                          marginLeft: "5px",
                        }}
                        sx={{
                          color: "common.blue",
                          cursor: "pointer",
                          backgroundColor: "common.white",
                          borderRadius: "50%",
                          padding: "5px",
                        }}
                      /> */}
                      {/* </Swiper> */}
                      {/* </Grid> */}
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item md={5} xs={12} sm={12}>
              <Card sx={{ padding: "8px" }}>
                <Typography
                  variant="body2"
                  color="common.black"
                  sx={{ fontWeight: "bold", fontSize: "18px" }}
                >
                  Chat Request
                </Typography>
                <Box
                  sx={{
                    height: "90vh",
                    overflowY: "auto",
                    paddingRight: "10px",
                    "&::-webkit-scrollbar": {
                      width: "8px",
                      height: "2px",
                    },
                    "&::-webkit-scrollbar-track": {
                      background: "#f1f1f1",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      // backgroundColor: theme.palette.primary.main,
                      backgroundColor: "common.lightGray",
                      borderRadius: "20px",
                      border: "2px solid #f1f1f1",
                      height: "4px",
                      minHeight: "30px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      // background: "common.brightBlue",
                    },
                  }}
                >
                  <UserCard />
                  <UserCard />
                  <UserCard />
                  <UserCard />
                  <UserCard />
                  <UserCard />
                  <UserCard />
                  <UserCard />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    )
  );
}

export default Dashboard;
