import axios from 'axios';
const API_URL = process.env.REACT_APP_SERVER_URL + "/admin/users";

export const createUser = async (data) => {
    const res = await axios.post(`${API_URL}`, data);
    return res.data
}

export const UpdateUser = async (id, data) => {
    const res = await axios.put(`${API_URL}/${id}`, data);
    return res.data
}
export const getUser = async (id) => {
    const res = await axios.get(`${API_URL}/${id}`);
    return res.data
}

export const getAllUsers = async () => {
    const res = await axios.get(`${API_URL}`);
    return res.data
}

export const deleteUser = async (id) => {
    const res = await axios.delete(`${API_URL}/${id}`);
    return res.data
}