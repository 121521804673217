import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import ThemeProviderCustom from './ThemeProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    }
  }
});
ReactDOM.render(
  <BrowserRouter >
    <QueryClientProvider client={queryClient}>
      <ThemeProviderCustom>
        <App />
      </ThemeProviderCustom>
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

