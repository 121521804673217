import axios from 'axios';
const API_URL = process.env.REACT_APP_SERVER_URL + "/admin/country";

export const createCountry = async (data) => {
    const res = await axios.post(`${API_URL}/create`, data);
    return res.data
}

export const updateCountry = async (id, data) => {
    const res = await axios.put(`${API_URL}/update_country/${id}`, data);
    return res.data
}
export const getCountry = async (id) => {
    const res = await axios.get(`${API_URL}/get_country/${id}`);
    return res.data
}

export const getAllCountries = async () => {
    const res = await axios.get(`${API_URL}/all_countries`);
    return res.data
}

export const deleteCountry = async (id) => {
    const res = await axios.delete(`${API_URL}/${id}`);
    return res.data
}