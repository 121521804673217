import { useEffect, useState, forwardRef } from "react";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Slide,
} from "@mui/material";
import { deleteEvent } from "../../_api/events";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import ReactStars from "react-stars";
import { useTheme } from "@mui/material/styles";

export const RateCardTable = (props) => {
  const theme = useTheme();
  const { submissions } = props;

  // const queryClient = useQueryClient()

  // useEffect(
  //     () => {
  //         if (!selectedEvents.length) {
  //             setSelectedEvents([]);
  //         }
  //     },
  //     [events]
  // );

  // const handleSelectAllUsers = (event) => {
  //     setSelectedEvents(event.target.checked ? events.map((user) => user.id) : []);
  // };

  // const handleSelectOneEvent = (eventId) => {
  //     if (!selectedEvents.includes(eventId)) {
  //         setSelectedEvents((prevSelected) => [...prevSelected, eventId]);
  //     } else {
  //         setSelectedEvents((prevSelected) =>
  //             prevSelected.filter((id) => id !== eventId)
  //         );
  //     }
  // };

  // const handleDeleteOrganization = async () => {
  //     toast.loading("Deleting...")
  //     try {
  //         await Promise.all(selectedEvents.map(id => deleteEvent(id)));
  //         toast.dismiss();
  //         toast.success("Event Deleted!");
  //         queryClient.invalidateQueries("get-events")
  //         setSelectedEvents([])
  //     } catch (err) {
  //         setSelectedEvents([])
  //         toast.dismiss();
  //         toast.error(err.message);
  //     }
  // }

  // const enableBulkActions = selectedEvents.length > 0;
  // const selectedSomeEvents =
  //     selectedEvents.length > 0 && selectedEvents.length < events.length;
  // const selectedAllEvents = selectedEvents.length === events?.length;
  // console.log("Selected Events: ", selectedEvents)
  return (
    <div
    // {...other}
    >
      {/* <Box
                sx={{
                    backgroundColor: "neutral.100",
                    display: !enableBulkActions && "none",
                    px: 2,
                    py: 0.5,
                }}
            > */}
      {/* <Checkbox
                    checked={selectedAllEvents}
                    indeterminate={selectedSomeEvents}
                    onChange={handleSelectAllUsers}
                />
                <Button onClick={handleDeleteOrganization} size="medium" style={{ marginRight: '10px' }} sx={{ ml: 2 }}>
                    Delete
                </Button>

                {selectedEvents.length == 1 && ( // permission change
                    <>
                        <Link to={`/events/edit/${selectedEvents[0]}`}>
                            <Button size="small" endIcon={<EditIcon />}>
                                Edit
                            </Button>
                        </Link>
                    </>
                )} */}

      {/* </Box> */}
      <Table sx={{ minWidth: 700, height: "100%", overflow: "auto" }}>
        <TableHead sx={{ visibility: "visible" }}>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>User Name</TableCell>
            <TableCell>Questions</TableCell>
            <TableCell>Ratings</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {submissions?.map((item, index) => {
            return (
              <TableRow hover key={item?.id}>
                <TableCell>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Box sx={{ ml: 1 }}>{index + 1}</Box>
                  </Box>
                </TableCell>

                <TableCell>
                  {/* <Link style={{ textDecoration: "none" }} to={`/events/view/${item.id}`}> */}
                  <Typography color="primary" variant="subtitle2">
                    {item.user?.firstName}
                  </Typography>
                  {/* </Link> */}
                </TableCell>

                <TableCell>
                  <Typography color="primary" variant="subtitle2">
                    {item.question_statement}
                  </Typography>
                </TableCell>

                <TableCell>
                  <ReactStars
                    edit={false}
                    size={14}
                    color2={theme.palette.primary.main}
                    color1="#aaa"
                    count={5}
                    value={item.star}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {/* <TablePagination
                component="div"
                count={eventCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
            /> */}
    </div>
  );
};
