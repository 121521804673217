import { Box, Button, Typography } from "@mui/material";
import React from "react";
import EventForm from "../../components/events/form";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function EditEvent() {
  const navigate = useNavigate();
  return (
    <Box sx={{ marginX: "10px" }}>
      <Button
        startIcon={<ArrowBackIcon />}
        variant="text"
        onClick={() => navigate(-1)}
      >
        Back
      </Button>
      <Typography variant="h2" color="initial">
        Edit Event
      </Typography>
      <EventForm isEdit={true} />
    </Box>
  );
}

export default EditEvent;
