import axios from "axios";
const API_URL = process.env.REACT_APP_SERVER_URL + "/admin/reports";

export const getReportedUsers = async () => {
  const res = await axios.get(`${API_URL}/reported-users`);
  return res.data;
};

export const blockUser = async (userId) => {
  const res = await axios.post(`${API_URL}/block-user/${userId}`);
  return res.data;
};

export const unblockUser = async (userId) => {
  const res = await axios.post(`${API_URL}/unblock-user/${userId}`);
  return res.data;
};
