import { useEffect, useState, forwardRef } from "react";
import toast from "react-hot-toast";
import EditIcon from "@mui/icons-material/Edit";
import { CircularProgress } from "@mui/material";
import { useQueryClient } from "react-query";
import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Slide,
} from "@mui/material";
// import { deleteCountry } from "../../_api/country";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const UsersTable = (props) => {
  const {
    users,
    userCount,
    onPageChange,
    onRowsPerPageChange,
    isLoading,
    page,
    rowsPerPage,
    ...other
  } = props;
  const [selectedCountries, setSelectedCountries] = useState([]);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectedCountries.length) {
      setSelectedCountries([]);
    }
  }, [users]);

  const handleSelectAllUsers = (event) => {
    setSelectedCountries(
      event.target.checked ? users.map((user) => user.id) : []
    );
  };

  const handleSelectOneUser = (event, skillId) => {
    if (!selectedCountries.includes(skillId)) {
      setSelectedCountries((prevSelected) => [...prevSelected, skillId]);
    } else {
      setSelectedCountries((prevSelected) =>
        prevSelected.filter((id) => id !== skillId)
      );
    }
  };

  // const handleDeleteOrganization = async () => {
  //     toast.loading("Deleting...")
  //     try {
  //         await Promise.all(selectedCountries.map(id => deleteCountry(id)));
  //         toast.dismiss();
  //         toast.success("Countries deleted!");
  //         queryClient.invalidateQueries("get-users")
  //         setSelectedCountries([])
  //     } catch (err) {
  //         setSelectedCountries([])
  //         toast.dismiss();
  //         toast.error(err.message);
  //     }
  // }

  const enableBulkActions = selectedCountries.length > 0;
  const selectedSomeCountries =
    selectedCountries.length > 0 && selectedCountries.length < users.length;
  const selectedAllCountries = selectedCountries.length === users?.length;

  return (
    <div {...other}>
      <Box
        sx={{
          backgroundColor: "neutral.100",
          display: !enableBulkActions && "none",
          px: 2,
          py: 0.5,
        }}
      >
        {/* <Checkbox
                    checked={selectedAllCountries}
                    indeterminate={selectedSomeCountries}
                    onChange={handleSelectAllUsers}
                />
                <Button onClick={handleDeleteOrganization} size="medium" style={{ marginRight: '10px' }} sx={{ ml: 2 }}>
                    Delete
                </Button>

                {selectedCountries.length == 1 && ( // permission change
                    <>
                        <Link to={`/users/edit/${selectedCountries[0]}`}>
                            <Button size="small" endIcon={<EditIcon />}>
                                Edit
                            </Button>
                        </Link>
                    </>
                )} */}
      </Box>
      <Table sx={{ minWidth: 700 }}>
        <TableHead
          sx={{ visibility: enableBulkActions ? "collapse" : "visible" }}
        >
          <TableRow>
            {/* <TableCell>Select</TableCell> */}
            <TableCell>#</TableCell>
            <TableCell>Image</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>First Name</TableCell>
            {/* <TableCell>Last Name</TableCell> */}
            <TableCell>Height</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={6}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            // permission change
            users?.map((user, index) => {
              const isUserSelected = selectedCountries.includes(user.id);

              return (
                <TableRow
                  hover
                  key={user?.id}
                  selected={isUserSelected}
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigate(`/users/view/${user?.id}`)}
                >
                  {/* <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={isUserSelected}
                                            onChange={(event) =>
                                                handleSelectOneUser(event, user?.id)
                                            }
                                            value={isUserSelected}
                                        />
                                    </TableCell> */}

                  <TableCell>
                    <Box
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <Box sx={{ ml: 1 }}>{page * rowsPerPage + index + 1}</Box>
                    </Box>
                  </TableCell>

                  <TableCell>
                    <Typography color="primary" variant="subtitle2">
                      <img
                        style={{
                          maxWidth: "70px",
                          maxHeight: "70px",
                          objectFit: "contain",
                        }}
                        src={user.profile_image}
                        alt="Profile"
                      />
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {/* <Link
                        style={{ textDecoration: "none" }}
                        // to={`/users/view/${user?.id}`}
                      > */}
                    <Typography color="primary" variant="subtitle2">
                      {user.email}
                    </Typography>
                    {/* </Link> */}
                  </TableCell>

                  <TableCell>
                    <Typography color="primary" variant="subtitle2">
                      {user.firstName}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <Typography color="primary" variant="subtitle2">
                      {user.height}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={userCount}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </div>
  );
};
